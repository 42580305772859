import React, { useRef } from "react";
import { Box, Container, Typography, Paper, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion, useInView } from "framer-motion";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
const FeaturesSection = () => {
  const theme = useTheme();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.3 });

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <Box
      sx={{
        padding: "80px 0",
        backgroundColor: theme.palette.background.default,
      }}
      id="features"
      ref={ref}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "40px",
            color: theme.palette.text.primary,
          }}
        >
          Features
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <motion.div
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={cardVariants}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: "44px",
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: theme.shadows[4],
                  borderRadius: "10px",
                  minHeight: "144px",
                  textAlign: "center",
                }}
              >
                <FormatListBulletedIcon
                  sx={{
                    fontSize: "48px",
                    color: theme.palette.secondary.main,
                    marginBottom: "16px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.text.primary,
                    marginBottom: "8px",
                  }}
                >
                  Track Workouts
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Easily record workouts in a way that works.
                </Typography>
                <Box
                  component="img"
                  src="/images/workout-ui.gif" // Replace with your image URL
                  alt="Workout Ui"
                  sx={{
                    marginTop: "16px",
                    width: "80%", // Adjust width
                    maxWidth: 280, // Maximum width
                    height: "auto", // Maintain aspect ratio
                    borderRadius: 2, // Optional: Rounded corners
                    boxShadow: 3, // Optional: Add shadow
                  }}
                />
              </Paper>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={4}>
            <motion.div
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={cardVariants}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: "44px",
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: theme.shadows[4],
                  borderRadius: "10px",
                  minHeight: "144px",
                  textAlign: "center",
                }}
              >
                <TrackChangesIcon
                  sx={{
                    fontSize: "48px",
                    color: theme.palette.secondary.main,
                    marginBottom: "16px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.text.primary,
                    marginBottom: "8px",
                  }}
                >
                  View Stats
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Watch your progress over time with detailed statistics and
                  graphs for each exercise.
                </Typography>
                <Box
                  component="img"
                  src="/images/weekly-stats-ui.png" // Replace with your image URL
                  alt="Weekly Stats Chart"
                  sx={{
                    marginTop: "16px",
                    width: "100%", // Adjust width
                    maxWidth: 400, // Maximum width
                    height: "auto", // Maintain aspect ratio
                    borderRadius: 2, // Optional: Rounded corners
                    boxShadow: 3, // Optional: Add shadow
                  }}
                />
              </Paper>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={4}>
            <motion.div
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={cardVariants}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: "44px",
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: theme.shadows[4],
                  borderRadius: "10px",
                  minHeight: "144px",
                  textAlign: "center",
                }}
              >
                <SubtitlesIcon
                  sx={{
                    fontSize: "48px",
                    color: theme.palette.secondary.main,
                    marginBottom: "16px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.text.primary,
                    marginBottom: "8px",
                  }}
                >
                  Workout Templates
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Apply progressive overload to each workout without having to
                  remember what you did last time.
                </Typography>
                <Box
                  component="img"
                  src="/images/workout-template-ui.png" // Replace with your image URL
                  alt="Workout Template"
                  sx={{
                    marginTop: "16px",
                    width: "100%", // Adjust width
                    maxWidth: 400, // Maximum width
                    height: "auto", // Maintain aspect ratio
                    borderRadius: 2, // Optional: Rounded corners
                    boxShadow: 3, // Optional: Add shadow
                  }}
                />
              </Paper>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesSection;
