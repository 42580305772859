import React, { useState } from "react";
import {
  IconButton,
  MenuItem,
  Typography,
  Box,
  Drawer,
  Divider,
  ListItemButton,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { getProfile } from "../redux/slices/profileSlice";
import { FitnessCenter as FitnessCenterIcon } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { clearAuthToken } from "../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import {
  clearCurrentWorkout,
  clearCurrentTemplate,
  createWorkout,
} from "../redux/slices/workoutSlice";
import FeedbackDialog from "./Account/Feedback/FeedbackDialog";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const NavBar = ({ isMenuDisabled, handleInstallClick, isInstallable }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { token } = useSelector((state) => state.authSlice);
  const { workoutTemplates } = useSelector((state) => state.profileSlice);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setDrawerOpen(true);
  };

  const handleMenuClose = () => {
    setDrawerOpen(false);
  };

  const handleNavigateToTemplateConfig = (template_id) => {
    navigate(`/workout-template/${template_id}`);
  };

  const handleLogout = () => {
    setDrawerOpen(false);
    dispatch(clearAuthToken());
    dispatch(clearCurrentWorkout());
    navigate("/welcome");
  };

  const handleCreateBlankWorkout = () => {
    const is_template = false;
    dispatch(createWorkout({ token, is_template }));
  };

  const handleNavigateToWorkouts = () => {
    setDrawerOpen(false);
    dispatch(getProfile({ token }));
    dispatch(clearCurrentWorkout());
    navigate("/workouts");
  };

  const handleNavigateHome = () => {
    setDrawerOpen(false);
    dispatch(getProfile({ token }));
    dispatch(clearCurrentWorkout());
    dispatch(clearCurrentTemplate());
    navigate("/");
  };

  const handleReportFeedback = async (feedback, feedbackType) => {
    console.log("handleReport Feedback", feedback, feedbackType);
    const result = await axios.post(
      `/api/account/createFeedback`,
      {
        feedback,
        feedbackType,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(result);
  };

  const handleToggleFeedbackDialog = () => {
    setFeedbackOpen(!feedbackOpen);
  };

  return (
    <Box>
      {isMenuDisabled ? (
        <Box>
          <IconButton
            size="large"
            edge="end"
            color={theme.palette.text.primary}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            open={drawerOpen}
            onClick={drawerOpen ? handleMenuClose : handleMenuOpen}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingBottom: "16px",
                paddingRight: "16px",
              }}
            >
              <FitnessCenterIcon sx={{ marginRight: "8px" }} />
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, color: theme.palette.text.primary }}
              >
                Weightliftr
              </Typography>
            </Box>
            <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
            <MenuItem onClick={() => navigate("/register")}>Register</MenuItem>
            {isInstallable ? (
              <MenuItem onClick={handleInstallClick}>Install App</MenuItem>
            ) : null}
          </Drawer>
        </Box>
      ) : (
        <Box>
          <Box>
            <IconButton
              size="large"
              edge="end"
              color={theme.palette.text.primary}
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Drawer
            open={drawerOpen}
            onClick={drawerOpen ? handleMenuClose : handleMenuOpen}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingBottom: "16px",
                paddingRight: "16px",
              }}
            >
              <FitnessCenterIcon sx={{ marginRight: "8px" }} />
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, color: theme.palette.text.primary }}
              >
                Weightliftr
              </Typography>
            </Box>
            <List>
              <ListItem>
                <Typography>Quick Nav</Typography>
              </ListItem>

              {workoutTemplates.map((template) => {
                return (
                  <ListItemButton
                    onClick={() =>
                      handleNavigateToTemplateConfig(template.workout_id)
                    }
                  >
                    <ListItemIcon>
                      <PlayCircleFilledWhiteIcon />
                    </ListItemIcon>
                    <ListItemText primary={template.workout_text} />
                  </ListItemButton>
                );
              })}
              <ListItemButton onClick={handleCreateBlankWorkout}>
                <ListItemIcon>
                  <AddCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Create Blank Workout" />
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={handleNavigateHome}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <Typography>Home</Typography>
              </ListItemButton>
              <Divider />
            </List>

            <MenuItem onClick={handleNavigateToWorkouts}>
              Past Workouts
            </MenuItem>
            <MenuItem onClick={handleToggleFeedbackDialog}>
              Give feedback!
            </MenuItem>
            {isInstallable ? (
              <MenuItem onClick={handleInstallClick}>Install App</MenuItem>
            ) : null}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Drawer>

          <FeedbackDialog
            open={feedbackOpen}
            handleReportFeedback={handleReportFeedback}
            onClose={handleToggleFeedbackDialog}
          />
        </Box>
      )}
    </Box>
  );
};

export default NavBar;
