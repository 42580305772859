import React from "react";
import { Box } from "@mui/material";
import NavBar from "../NavBar";
import FeaturesSection from "./components/FeaturesSection";
import HeroSection from "./components/HeroSection";
// import TestimonialsSection from "./components/TestimonialsSection";
import PricingSection from "./components/PricingSection";
import CallToAction from "./components/CallToAction";
import Footer from "./components/Footer";

const LandingPage = () => {
  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
      >
        <NavBar isMenuDisabled={true} />
      </Box>
      <HeroSection />
      <FeaturesSection />
      {/* <TestimonialsSection /> */}
      <PricingSection />
      <CallToAction />
      <Footer />
    </Box>
  );
};

export default LandingPage;
