import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Checkbox,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWorkout,
  getWorkout,
  clearCurrentWorkout,
  createTemplateFromWorkout,
} from "../../redux/slices/workoutSlice";
import { getProfile } from "../../redux/slices/profileSlice";
import Exercise from "./Exercise";
import WorkoutMenu from "./WorkoutMenu";
import axios from "axios";
import WorkoutTitle from "./WorkoutTitle";
import NavBar from "../NavBar";
import { useParams, useNavigate } from "react-router-dom";
import { getRootDomain } from "../../utils/get_domain";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Workout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const { user } = useSelector((state) => state.profileSlice);
  const { workout_id } = useParams();
  const { workout } = useSelector((state) => state.workoutSlice);
  const [workoutActivities, setWorkoutActivities] = useState([]);
  const [bottomNavigationState, setBottomNavigationState] = useState("view");

  useEffect(() => {
    if (workout_id) {
      dispatch(getWorkout({ token, workout_id }));
    }
  }, [workout_id, dispatch, token]);

  useEffect(() => {
    if (workout) {
      console.log("setWorkoutActivities", workout.allWorkoutActivities);
      setWorkoutActivities(workout.allWorkoutActivities);
    }
  }, [workout]);

  const handleDeleteWorkout = async () => {
    try {
      await dispatch(deleteWorkout({ token, workout_id }));
      navigate("/");
    } catch (error) {
      console.error("Failed to delete workout:", error);
    }
  };

  const handleFinishWorkout = () => {
    try {
      dispatch(clearCurrentWorkout());
      dispatch(getProfile({ token }));
      navigate("/");
    } catch (error) {
      console.error("Failed to finish workout:", error);
    }
  };

  const handleAddExerciseToWorkout = async (exercise) => {
    const result = await axios.post(
      `/api/exercise/createExercise`,
      {
        workout_id,
        exercise,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(result);
    const { exercise_id } = result.data;
    const savedExercise = {
      type: "exercise",
      parsed: `${exercise.reps} X ${exercise.sets} ${exercise.name} @ ${
        exercise.weight
      } ${exercise.exerciseUnit === 0 ? "lbs" : "kgs"}`,
      data: {
        exercise_id,
        name: exercise.name,
        sets: exercise.sets,
        reps: exercise.reps,
        weight: exercise.weight,
        weight_unit: exercise.exerciseUnit,
      },
    };
    console.log("saved", savedExercise, workoutActivities);
    setWorkoutActivities([...workoutActivities, savedExercise]);
  };

  const handleAddRowToWorkout = async (row) => {
    console.log("row", row);
    const result = await axios.post(
      `/api/exercise/createRow`,
      {
        workout_id,
        row,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(result);
    const { row_id } = result.data;
    const savedRow = {
      parsed: `${row.rowDistance}m row in ${row.rowTime}`,
      type: "row",
      data: {
        row_id,
      },
    };
    setWorkoutActivities([...workoutActivities, savedRow]);
  };

  const handleAddRunToWorkout = async (run) => {
    console.log("run", run);
    const result = await axios.post(
      `/api/exercise/createRun`,
      {
        workout_id,
        run,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(result);
    const { run_id } = result.data;
    const savedRun = {
      parsed: `${run.runDistance} ${run.runDistanceType} run in ${run.runTime}`,
      type: "run",
      data: {
        run_id,
      },
    };
    setWorkoutActivities([...workoutActivities, savedRun]);
  };

  const handleCheckBoxChange = async (activity, completed, index) => {
    console.log("checkBoxChangeForActivity", activity, completed);

    let copy = [...workoutActivities];
    console.log("copy", copy);
    copy[index] = { ...copy[index], completed };
    setWorkoutActivities(copy);

    const result = await axios.post(
      `/api/exercise/completeActivity`,
      {
        activity,
        completed,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (result.status !== 200) {
      console.log("Error occured with checkbox - reverting checkbox state");
      let copy = [...workoutActivities];
      copy[index] = { ...copy[index], completed: !completed };
      setWorkoutActivities(copy);
    }
  };

  const handleCopyWorkout = () => {
    const parsedStrings = workoutActivities.reduce((workout, activity) => {
      return `${workout}${activity.parsed}\n`;
    }, "");
    navigator.clipboard
      .writeText(parsedStrings)
      .then(() => {
        console.log("Workout copied to clipboard!");
        alert("Workout copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
      });
  };

  const handleCreateTemplateFromWorkout = () => {
    dispatch(createTemplateFromWorkout({ token, workout_id }));
    alert("Template created from workout.");
  };

  const handleShareWorkout = () => {
    const root = getRootDomain();
    const link = `${root}/shared-workout/${btoa(user.email)}/${workout_id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Shared workout copied to clipboard!");
        alert("Workout link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy link to clipboard:", error);
      });
  };

  const handleBottomNavigationChange = (value) => {
    console.log("handlbottomnavcahngage", value);
    if (value === 0) {
      setBottomNavigationState(0);
    }
    if (value === 1) {
      setBottomNavigationState(1);
    }
    if (value === 2) {
      setBottomNavigationState(2);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%" }}>
          <NavBar />
        </Box>
        <Box
          component={motion.div}
          layout
          transition={{ type: "spring", stiffness: 60, damping: 20 }}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              // height: "90%",
              width: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
              backgroundColor: theme.palette.background.paper,
              borderRadius: "10px",
            }}
            component={motion.div}
            layout
          >
            {workout ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: "16px",
                }}
              >
                <Box>
                  <WorkoutTitle />
                </Box>
                <WorkoutMenu
                  handleCopyWorkout={handleCopyWorkout}
                  handleDeleteWorkout={handleDeleteWorkout}
                  handleFinishWorkout={handleFinishWorkout}
                  handleCreateTemplateFromWorkout={
                    handleCreateTemplateFromWorkout
                  }
                  handleShareWorkout={handleShareWorkout}
                />
              </Box>
            ) : null}
            <AnimatePresence>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "left",
                  width: "100%",
                  maxHeight: bottomNavigationState === 0 ? "200px" : "440px",
                  overflow: "scroll",
                }}
              >
                {workoutActivities.map((activity, index) => {
                  return (
                    <Box
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        paddingBottom: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <Typography key={index} variant="body1">
                        {activity.parsed}
                      </Typography>
                      {bottomNavigationState === 2 ? (
                        <Checkbox
                          onChange={(event) =>
                            handleCheckBoxChange(
                              activity,
                              event.target.checked,
                              index
                            )
                          }
                          checked={activity.completed}
                        />
                      ) : null}
                    </Box>
                  );
                })}
              </Box>

              {bottomNavigationState === 0 ? (
                <Exercise
                  addRow={handleAddRowToWorkout}
                  addExercise={handleAddExerciseToWorkout}
                  addRun={handleAddRunToWorkout}
                  workoutActivities={workoutActivities}
                />
              ) : null}
            </AnimatePresence>
          </Card>
          <BottomNavigation
            showLabels
            value={"walue"}
            onChange={(event, newValue) => {
              console.log(newValue);
              handleBottomNavigationChange(newValue);
            }}
            sx={{ borderRadius: "16px", marginTop: "16px" }}
          >
            <BottomNavigationAction
              id="Edit"
              label="Edit"
              icon={<EditIcon />}
            />
            <BottomNavigationAction
              id="View"
              label="View"
              icon={<VisibilityIcon />}
            />
            <BottomNavigationAction
              id="Complete"
              label="Complete"
              icon={<CheckBoxIcon />}
            />
          </BottomNavigation>
        </Box>
      </Box>
    </Box>
  );
};

export default Workout;
