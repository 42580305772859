import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"; // Import Framer Motion
import { Box, TextField, Typography, IconButton } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RowingIcon from "@mui/icons-material/Rowing";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { FitnessCenter as FitnessCenterIcon } from "@mui/icons-material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

const exerciseTypes = ["standard", "row", "run"];
const runDistanceTypes = ["Meter", "Kilometer", "Mile"];

const Exercise = ({ addExercise, addRow, addRun, workoutActivities }) => {
  const [reps, setReps] = useState("");
  const [sets, setSets] = useState("");
  const [name, setName] = useState("");
  const [weight, setWeight] = useState("");
  const [rowDistance, setRowDistance] = useState("");
  const [rowTime, setRowTime] = useState("");
  const [runTime, setRunTime] = useState("");
  const [exerciseUnit, setExerciseUnit] = useState(0);
  const [runDistanceType, setRunDistanceType] = useState(0);
  const [runDistance, setRunDistance] = useState("");
  const [exerciseTypeIndex, setExerciseTypeIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [addActivityMemory, setAddActivityMemory] = useState([]);
  const [activityMemoryIndex, setActivityMemoryIndex] = useState(-1);

  useEffect(() => {
    if (workoutActivities && workoutActivities.length > 0) {
      setAddActivityMemory([...workoutActivities]);
    }
  }, [workoutActivities]);

  const handleShowAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 3000);
  };

  const handleAddExercise = () => {
    switch (exerciseTypes[exerciseTypeIndex]) {
      case "standard":
        if (reps === "" || sets === "" || name === "" || weight === "") {
          handleShowAlert("Missing exercise information");
          break;
        }
        addExercise({ reps, sets, name, weight, exerciseUnit });
        setAddActivityMemory([
          ...addActivityMemory,
          {
            type: "exercise",
            data: { reps, sets, name, weight, exerciseUnit },
          },
        ]);
        break;
      case "row":
        if (rowTime === "" || rowDistance === "") {
          handleShowAlert("Missing row information");
          break;
        }
        console.log("adding row in switch,", rowDistance, rowTime);
        addRow({ rowDistance, rowTime });
        break;
      case "run":
        if (runDistance === "" || runTime === "") {
          handleShowAlert("Missing run information");
          break;
        }
        console.log("adding run in switch,", runDistance, runTime);
        addRun({
          runDistance,
          runTime,
          runDistanceType: runDistanceTypes[runDistanceType],
        });
        break;
      default:
        console.log(
          `handleAddExercise not handled ${exerciseTypes[exerciseTypeIndex]}`
        );
    }

    clearFields();
  };

  const clearFields = () => {
    setReps("");
    setSets("");
    setName("");
    setWeight("");
    setRowDistance("");
    setRowTime("");
    setRunTime("");
    setRunDistance("");
  };

  const handlePopulateFromMemory = () => {
    switch (exerciseTypes[exerciseTypeIndex]) {
      case "standard":
        const standardExercises = addActivityMemory.filter(
          (activity) => activity.type === "exercise"
        );

        let exerciseToAdd = null;
        if (activityMemoryIndex <= 0) {
          exerciseToAdd = standardExercises[standardExercises.length - 1].data;
        } else {
          exerciseToAdd = standardExercises[activityMemoryIndex - 1].data;
        }

        setExerciseUnit(exerciseToAdd.weight_unit);
        setSets(exerciseToAdd.sets);
        setName(exerciseToAdd.name);
        setWeight(exerciseToAdd.weight);
        setReps(exerciseToAdd.reps);
        if (activityMemoryIndex <= 0) {
          setActivityMemoryIndex(standardExercises.length - 1);
        } else {
          setActivityMemoryIndex(activityMemoryIndex - 1);
        }
        break;
      default:
        console.log("handlePopulateFromMemory not handled");
    }
  };

  const changeExerciseType = () => {
    if (exerciseTypeIndex + 1 === exerciseTypes.length) {
      setExerciseTypeIndex(0);
    } else {
      setExerciseTypeIndex(exerciseTypeIndex + 1);
    }
  };

  const handleSetRunDistanceType = () => {
    if (runDistanceType === runDistanceTypes.length - 1) {
      setRunDistanceType(0);
    } else {
      const updatedType = runDistanceType + 1;
      setRunDistanceType(updatedType);
    }
  };

  return (
    <motion.div
      key={exerciseTypes[exerciseTypeIndex]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      {exerciseTypes[exerciseTypeIndex] === "standard" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{ display: "flex", width: "55%", justifyContent: "flex-end" }}
            >
              <FitnessCenterIcon sx={{ paddingBottom: "16px" }} />
            </Box>
            <Box
              sx={{ display: "flex", width: "45%", justifyContent: "flex-end" }}
            >
              <IconButton
                sx={{ paddingBottom: "16px" }}
                onClick={() => setExerciseUnit(exerciseUnit === 0 ? 1 : 0)}
              >
                <Typography>{exerciseUnit === 0 ? "LB" : "KG"}</Typography>
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              sx={{ width: "125px" }}
              placeholder="Reps"
              type={"number"}
              value={reps}
              onChange={(e) => setReps(e.target.value)}
            />
            <Typography
              sx={{ paddingLeft: "8px", paddingRight: "8px" }}
              variant="body1"
            >
              x
            </Typography>
            <TextField
              sx={{ width: "125px" }}
              placeholder="Sets"
              type="number"
              value={sets}
              onChange={(e) => setSets(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              sx={{ width: "125px" }}
              placeholder="Exercise"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Typography
              sx={{ paddingLeft: "8px", paddingRight: "8px" }}
              variant="body1"
            >
              @
            </Typography>
            <TextField
              type="number"
              sx={{ width: "125px" }}
              placeholder="Weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </Box>
        </Box>
      ) : exerciseTypes[exerciseTypeIndex] === "row" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <RowingIcon sx={{ paddingBottom: "16px" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              sx={{ width: "125px" }}
              placeholder="Meters"
              value={rowDistance}
              onChange={(e) => setRowDistance(e.target.value)}
            />
            <Typography
              sx={{ paddingLeft: "8px", paddingRight: "8px" }}
              variant="body1"
            >
              @
            </Typography>
            <TextField
              sx={{ width: "125px" }}
              placeholder="Time"
              value={rowTime}
              onChange={(e) => setRowTime(e.target.value)}
            />
          </Box>
        </Box>
      ) : exerciseTypes[exerciseTypeIndex] === "run" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{ display: "flex", width: "55%", justifyContent: "flex-end" }}
            >
              <DirectionsRunIcon sx={{ paddingBottom: "16px" }} />
            </Box>
            <Box
              sx={{ display: "flex", width: "45%", justifyContent: "flex-end" }}
            >
              <IconButton
                sx={{ paddingBottom: "16px" }}
                onClick={handleSetRunDistanceType}
              >
                <Typography>{runDistanceTypes[runDistanceType]}</Typography>
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              sx={{ width: "120px" }}
              placeholder={runDistanceTypes[runDistanceType]}
              value={runDistance}
              onChange={(e) => setRunDistance(e.target.value)}
            />
            <Typography
              sx={{ paddingLeft: "8px", paddingRight: "8px" }}
              variant="body1"
            >
              @
            </Typography>
            <TextField
              sx={{ width: "120px" }}
              placeholder="Time"
              value={runTime}
              onChange={(e) => setRunTime(e.target.value)}
            />
          </Box>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          justifyContent:
            exerciseTypes[exerciseTypeIndex] === "standard"
              ? "space-between"
              : "flex-end",
          paddingTop: "8px",
        }}
      >
        {exerciseTypes[exerciseTypeIndex] === "standard" ? (
          <IconButton onClick={handlePopulateFromMemory}>
            <img src="/images/brain.png" alt="Brain" width="24" height="24" />
          </IconButton>
        ) : null}
        <IconButton onClick={changeExerciseType}>
          <ChangeCircleIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IconButton onClick={handleAddExercise}>
          <ControlPointIcon color="text.secondary" />
        </IconButton>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        key={"topcenter"}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default Exercise;
