import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";

const FeedbackDialog = ({ open, onClose, handleReportFeedback }) => {
  const [feedback, setFeedback] = useState("");
  const [feedbackType, setFeedbackType] = useState("general");

  const handleSubmit = () => {
    if (feedback.trim()) {
      handleReportFeedback(feedback, feedbackType);
      setFeedback("");
      setFeedbackType("general");
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Send Feedback</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          As a solo developer, I truly value your feedback. Your input helps me
          improve and shape this project to better serve users like you. Thank
          you for taking the time to share your thoughts!
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Feedback Type</InputLabel>
          <Select
            value={feedbackType}
            onChange={(e) => setFeedbackType(e.target.value)}
          >
            <MenuItem value="bug">Bug Report</MenuItem>
            <MenuItem value="feature">Feature Request</MenuItem>
            <MenuItem value="general">General Feedback</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Your Feedback"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!feedback.trim()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
